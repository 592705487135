:root {
  --background-color: #ffffff;
  --primary-blue: #2364b0;
  --secondary-blue: #1e488a;
  --hover-color: #3c8ed9;
  --light-blue: #e6f2ff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  list-style-type: none;
}

body {
  overflow-x: hidden;
  font-family: "Rubik", sans-serif;
  z-index: 999;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

nav {
  background: var(--background-color);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 15px 30px;
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: all 0.3s ease;
}

.logo img {
  height: 60px;
  transition: transform 0.3s ease;
}

.main_menu {
  display: flex;
  align-items: center;
  gap: 20px;

  > ul {
    display: flex;
    align-items: center;
    gap: 20px;

    > li {
      position: relative;

      &:hover > .sub_menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }
    }

    a {
      color: #353535;
      padding: 20px;
      font-size: 16px;
      font-weight: 550;
      transition: color 0.3s ease, border-bottom 0.3s ease;

      &.active,
      &:hover {
        color: var(--primary-blue);
        border-bottom: 2px solid var(--hover-color);
      }
    }
  }

  .sub_menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    background: var(--background-color);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(10px);
    transition: all 0.3s ease;
    z-index: 999;

    li {
      a {
        padding: 10px 15px;
        font-size: 14px;
        color: var(--primary-blue);
        display: block;
        transition: background 0.3s ease, color 0.3s ease;

        &:hover {
          color: var(--secondary-blue);
          background: var(--light-blue);
        }
      }
    }
  }
}

.mobile_btn {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: var(--primary-blue);
}

@media (max-width: 1000px) {
  .mobile_btn {
    display: block;
  }

  .main_menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    background: var(--background-color);
    padding: 30px 20px;
    flex-direction: column;
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
    transition: transform 0.3s ease;

    &.active {
      display: flex;
      transform: translateX(0);
    }

    ul {
      flex-direction: column;
      gap: 10px;
      width: 100%;

      li {
        width: 100%;

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          font-size: 18px;
          border-bottom: 1px solid #ddd;
          color: #353535;
          cursor: pointer;
          transition: background 0.3s ease, color 0.3s ease;

          &:hover {
            color: var(--primary-blue);
            background: var(--light-blue);
          }

          svg {
            margin-left: 5px;
            transition: transform 0.3s ease;
            transform: rotate(0deg);
          }

          &.active svg {
            transform: rotate(180deg);
          }
        }

        .sub_menu {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease, opacity 0.3s ease;
          background: var(--light-blue);
          padding-left: 15px;

          li a {
            padding: 10px 15px;
            font-size: 16px;
          }

          &.active {
            max-height: 500px; // Adjust based on content
            opacity: 1;
          }
        }
      }
    }
  }

  .main_menu ul li.has_dropdown > a {
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .logo img {
    height: 50px;
  }
}
