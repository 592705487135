.privacy-policy {
  padding: 2rem;
  max-width: 700px;
  margin: 0 auto;
  background-color: #fff;
  font-family: Arial, sans-serif;
  color: #333;
  text-align: left;
  line-height: 1.6;

  &__title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #003366;
    text-align: center;
    margin-bottom: 2rem;
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    transform: translateY(-20px);
    animation: slideIn 0.8s forwards;
  }

  &__effective-date {
    font-size: 0.9rem;
    color: #555;
    text-align: center;
    margin-bottom: 2.5rem;
    opacity: 0;
    transform: translateY(10px);
    animation: fadeInUp 0.6s forwards 0.3s;
  }

  &__section {
    opacity: 0;
    transform: translateY(20px);
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: all 0.5s ease;
    animation: fadeInUp 0.8s forwards;

    &.fade-in {
      opacity: 1;
      transform: translateY(0);
    }

    h2 {
      font-size: 1.6rem;
      color: #003366;
      margin-bottom: 0.6rem;
    }

    p {
      font-size: 1rem;
      color: #333;
    }

    strong {
      color: #003366;
    }
  }
  /* Responsive adjustments */
  @media (max-width: 768px) {
    padding: 1.5rem;
    &__title {
      font-size: 2rem;
    }
    &__section {
      padding: 1rem;
      h2 {
        font-size: 1.5rem;
      }
      p,
      li {
        font-size: 0.95rem;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 1rem;
    &__title {
      font-size: 1.75rem;
    }
    &__section {
      padding: 0.75rem;
      h2 {
        font-size: 1.25rem;
      }
      p,
      li {
        font-size: 0.9rem;
      }
    }
  }
}

/* Animation Keyframes */
@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
