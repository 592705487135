.events {
  padding: 20px;
  margin-left: 15px;

  h2 {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;

    .event-date {
      flex: 0 0 60px;
      text-align: center;
      border-right: 1px solid #e0e0e0;
      padding-right: 10px;

      .event-day {
        font-size: 1.5em;
        font-weight: bold;
      }

      .event-month {
        font-size: 1em;
      }
    }

    .event-info {
      flex: 1;
      padding-left: 10px;

      h3 {
        margin: 0;
        font-size: 1.2em;
      }

      p {
        margin: 5px 0;
      }
    }
  }
}
