.notice-board-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f7fa;
  position: relative;
  overflow: hidden;

  /* Main background shapes */
  &:before,
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    //opacity: 0.2;
    animation: floatShapes 10s infinite alternate ease-in-out;
  }

  &:before {
    top: -300px;
    left: -300px;
    width: 600px;
    height: 600px;
    background-color: rgba(0, 123, 255, 0.2);
  }

  &:after {
    bottom: -300px;
    right: -300px;
    width: 600px;
    height: 600px;
    background-color: rgba(0, 123, 255, 0.2);
    z-index: 1;
  }

  /* Responsive layout */
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .filters-box {
      width: 100%;
      margin-bottom: 20px;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      //opacity: 0.2;
      animation: floatShapes 10s infinite alternate ease-in-out;
    }

    &:before {
      top: -100px;
      left: -100px;
      width: 200px;
      height: 200px;
      background-color: rgba(0, 123, 255, 0.2);
    }

    &:after {
      bottom: -100px;
      right: -100px;
      width: 200px;
      height: 200px;
      background-color: rgba(0, 123, 255, 0.2);
      z-index: 1;
    }
  }
}

@keyframes floatShapes {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 500px);
  }
  50% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -500px);
  }
}

/* Filter box styling */
.filters-box {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  position: relative;
  margin-bottom: 20px;
  width: 80%;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 10;
  animation: slideInRight 0.5s ease;

  h3 {
    margin-bottom: 15px;
    color: #333;
    font-weight: 600;
  }

  input {
    width: 40%;
    padding: 12px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
  }

  .search-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
      background-color: #0056b3;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    input {
      width: 80%;
      padding: 12px;
      margin-bottom: 12px;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-size: 16px;
    }
  }
}

@keyframes slideInRight {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Notice board styling */
.notice-board {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999;
}

.notice-list {
  .notice-item {
    background-color: #fff;
    padding: 30px;
    margin: 20px 0;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* Adding a notice background shape */
    .notice-shape {
      position: absolute;
      top: -60px;
      right: -60px;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: rgba(255, 200, 100, 0.4);
      animation: floatCardShape 8s infinite alternate ease-in-out;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 40px rgba(0, 0, 0, 0.1);
    }

    .notice-header {
      text-align: center;
      margin-bottom: 20px;

      h2 {
        font-size: 22px;
        color: #333;
        font-weight: 700;
      }
    }

    .notice-info {
      display: flex;
      justify-content: space-around;
      align-items: center;

      p {
        font-size: 14px;
        color: #555;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 8px;
          color: #007bff;
        }
      }

      .view-pdf-btn {
        padding: 10px 15px;
        background-color: #ff6b6b;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #ff4757;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .notice-item {
      flex-direction: column;
      text-align: center;
    }
    .notice-shape {
      top: -30px;
      right: -30px;
      width: 120px !important;
      height: 120px !important;
    }
    .notice-info {
      flex-direction: column;

      p {
        font-size: 12px !important;
      }

      .view-pdf-btn {
        margin-top: 5px;
        padding: 10px 25px !important;

        &:hover {
          background-color: #ff4757;
        }
      }
    }
  }
}

@keyframes floatCardShape {
  0% {
    transform: translate(-150px, 0);
  }
  25% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(0px, 150px);
  }
  100% {
    transform: translate(62px, 150px);
  }
}

/* Pagination styling */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 12px;
    margin: 0 5px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
