.card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  .icon {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
  }

  .learn-more {
    font-weight: bold;
    text-transform: uppercase;
    color: black;
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    .arrow {
      margin-left: 5px;
      font-size: 1.2rem;
    }
  }

  &:hover {
    transform: translateY(-5px);
  }
}
