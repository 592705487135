.banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 5px;
  height: 150px;
  margin-top: 0px;
  margin-bottom: 20px;
  background-color: #000; /* Default background color in case it's not passed as a prop */

  .logo {
    margin: 0px 55px;
    height: 80px;
    max-width: 100%;
  }

  .text {
    flex-grow: 1;
    padding: 0 20px;

    a {
      margin: 2px;
      font-size: 30px;
      color: #fff;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }

    p {
      margin: 2px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }
}

@media (max-width: 768px) {
  .banner {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    height: auto;
    padding: 20px;

    .logo {
      margin: 0 0 15px 0;
      height: 60px;
    }

    .text {
      padding: 0;

      a {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 15px;

    .logo {
      height: 50px;
    }

    .text {
      a {
        font-size: 20px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
