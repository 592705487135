.thesis-banner-container {
  padding: 2rem;
  background: linear-gradient(135deg, #0041c469 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, #0041c469 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, #0041c469 25%, transparent 25%),
    linear-gradient(45deg, #0041c469 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: #1a73e881; // Base background color
  width: 100%;
  min-height: 100vh;
  color: #1a2a44;

  .title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #1a73e8;
    text-align: center;
  }

  // Dynamic Filter Section with Department Dropdown
  .filter-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1.5rem;
    gap: 1rem;

    input,
    select {
      padding: 0.5rem;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 45%;

      &:focus {
        outline: none;
        border-color: #1a73e8;
      }
    }

    // New Department Dropdown
    .department-select {
      width: 45%;
    }
  }

  .thesis-banners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5rem;

    .thesis-banner {
      background-color: white;
      padding: 1.5rem;
      border-radius: 15px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      width: calc(50% - 1rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

      .banner-icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
          font-size: 3rem;
          color: #1a73e8;
        }

        .serial-number {
          font-size: 1.2rem;
          font-weight: bold;
          color: #1a73e8;
        }
      }

      .banner-content {
        text-align: left;
        width: 70%;
        padding-left: 1rem;

        .banner-title {
          font-size: 1.4rem;
          margin-bottom: 1rem;
          color: #1a73e8;
        }

        .info-box {
          background-color: #f0faff;
          padding: 0.6rem;
          margin-bottom: 0.5rem;
          border-radius: 10px;
          color: #333;
        }
      }

      .pdf-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .view-pdf-btn {
          background-color: #ecf4ff5c;
          color: #005ed9;
          font-weight: 700;
          letter-spacing: 1.5px;
          padding: 0.5rem 1.2rem;
          border: 1px solid #005ed9;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 1rem;
          transition: background-color 0.3s ease;
          animation: pulse 1.5s infinite;

          .pdf-icon {
            margin-right: 0.5rem;
          }

          &:hover {
            background-color: #155cb0;
            color: white;
          }

          &:active {
            background-color: #114d99;
          }
        }

        .pdf-not-available {
          color: red;
          font-weight: bold;
          margin-top: 0.5rem;
          font-size: 1rem;
          text-align: center;
          background: #ffebee;
          border-radius: 5px;
          padding: 0.5rem;
          border: 1px solid red;
          box-shadow: 0 2px 5px rgba(255, 0, 0, 0.2);
          animation: pulse 1.5s infinite;
        }
      }
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 1024px) {
    .thesis-banners {
      .thesis-banner {
        width: calc(50% - 1rem);
      }
    }
  }

  @media (max-width: 768px) {
    .thesis-banners {
      flex-direction: column;
      .thesis-banner {
        width: 100%;
        margin-bottom: 1.5rem;
        flex-direction: column;
        padding: 1.5rem 0.5rem;
      }
    }

    .title {
      font-size: 2rem;
    }

    .banner-content {
      width: 90%;
      .banner-title {
        font-size: 1.2rem;
      }

      .info-box {
        padding: 0.5rem;
        font-size: 0.9rem;
      }
    }

    .pdf-container {
      width: 100% !important;
      .view-pdf-btn {
        font-size: 0.8rem;
        padding: 0.6rem 1rem;
      }

      .pdf-not-available {
        font-size: 0.8rem;
      }
    }
  }

  @media (max-width: 480px) {
    .title {
      font-size: 1.5rem;
    }

    .thesis-banners {
      .thesis-banner {
        flex-direction: column;
      }

      .banner-content {
        width: 100%;
      }
    }

    .banner-icon {
      .icon {
        font-size: 2.5rem;
      }
    }

    .pdf-container {
      .view-pdf-btn {
        font-size: 0.8rem;
      }

      .pdf-not-available {
        font-size: 0.8rem;
      }
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
}
