.image-carousel {
  height: 500px;
  width: 50%;
}

.imagesList {
  height: 500px !important;
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .image-carousel {
    height: 300px;
    width: 100%;
  }

  .imagesList {
    height: 300px !important;
    width: 100% !important;
  }
}
