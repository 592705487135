.page-title-banner {
  width: 100%;
  max-height: 50vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  text-align: center;
  z-index: 99;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); // Adjust opacity if needed
    z-index: 1;
  }

  .title {
    font-size: 2rem; // Default font size for small screens
    font-weight: bold;
    z-index: 2; // Ensure text is above the overlay
    position: relative;
    margin: 0;
    letter-spacing: 2px;
  }

  // Media queries for responsiveness
  @media (min-width: 268px) {
    height: 20vh; // Increase banner height for medium screens
    .title {
      font-size: 1.5rem; // Adjust font size for medium screens
    }
  }
  @media (min-width: 768px) {
    height: 40vh; // Increase banner height for medium screens
    .title {
      font-size: 2.5rem; // Adjust font size for medium screens
    }
  }

  @media (min-width: 1024px) {
    height: 60vh; // Further increase banner height for larger screens
    .title {
      font-size: 3.5rem; // Larger font size for larger screens
    }
  }
}
