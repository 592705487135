@keyframes blink {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.3;
  }
  100% {
    scale: 1;
  }
}

.event-item {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  width: 120%;
  align-items: center;

  .imageSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    color: #fff;
    width: 75%;
    height: 65px;

    .images {
      height: 55px;
      padding: 5px 0px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
      display: flex;
      justify-content: center;
      line-height: 1;
    }
  }

  &.blinking {
    animation: blink 1.5s infinite;
  }

  .details {
    display: flex;
    flex-direction: row;
    width: 50%;

    .title {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      text-decoration: none;
      color: #3e3737;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .dateTime {
      font-size: 12px;
      margin: 2px 0;
      color: #666;
    }

    .location {
      color: #898989;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
