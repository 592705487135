.news-section {
  padding: 60px 20px;
  background: #ffffff;
  text-align: center;
  overflow: hidden;
  position: relative;

  .news-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 50px;
    position: relative;
    display: inline-block;
    background: linear-gradient(90deg, #0056b3, #0078d4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: fadeIn 1s ease-in-out;
  }

  .news-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
  }
  .news-card {
    background: linear-gradient(145deg, #ffffff, #ffffff);
    border-radius: 15px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15),
      -4px -4px 10px rgba(255, 255, 255, 0.8);
    overflow: hidden;
    width: 300px;
    height: 450px;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    text-align: left;

    // Border beam animation
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid transparent;
      border-radius: 15px;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(90deg, #0078d4, #ff6a00, #0078d4) border-box;
      animation: moveBorder 4s linear infinite;
      z-index: 1;
    }

    &:hover {
      transform: translateY(-10px) scale(1.02);
      box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.2),
        -6px -6px 15px rgba(255, 255, 255, 0.9);
    }

    .news-image-container {
      width: 100%;
      height: 60%;
      overflow: hidden;
      position: relative;
      z-index: 2;
      padding: 5px;

      .news-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.5s ease;
      }

      &:hover .news-image {
        transform: scale(1.1);
      }
    }

    .news-content {
      margin: 4px;
      padding: 20px;
      border-radius: 5px;
      background: linear-gradient(145deg, #ffffff, #f2f2f2);
      z-index: 2;

      h3 {
        font-size: 1rem;
        color: #0056b3;
        margin-bottom: 10px;
        font-weight: bold;
      }

      p {
        font-size: 0.8rem;
        color: #555;
        line-height: 1.5;
      }
    }
  }

  .see-all-button {
    display: inline-block;
    margin-top: 40px;
    padding: 15px 30px;
    background: linear-gradient(145deg, #0078d4, #0056b3);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    text-decoration: none;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15),
      -4px -4px 10px rgba(255, 255, 255, 0.8);
    transition: background 0.3s ease, transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
      background: linear-gradient(145deg, #0056b3, #0078d4);
    }
  }

  @media (max-width: 768px) {
    .news-container {
      flex-direction: column;
    }

    .news-card {
      .news-image-container {
        width: 100%;
        height: 40%;

        .news-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transition: transform 0.5s ease;
        }

        &:hover .news-image {
          transform: scale(1.1);
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes moveBorder {
  0% {
    border-image-source: linear-gradient(90deg, #0078d4, #ff6a00, #0078d4);
  }
  50% {
    border-image-source: linear-gradient(180deg, #ff6a00, #0078d4, #ff6a00);
  }
  100% {
    border-image-source: linear-gradient(270deg, #0078d4, #ff6a00, #0078d4);
  }
}
