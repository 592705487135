.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  height: auto;
  background: linear-gradient(to right, #e0f7fa 0%, #f7e4fc 50%, #e0f7fa 100%);

  overflow: hidden;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      45deg,
      rgb(235, 235, 235),
      rgba(0, 50, 249, 0.278) 10px,
      transparent 10px,
      transparent 20px
    );
    z-index: 1;
    opacity: 0.3;
    transform: rotate(15deg); // Rotation for diagonal lines
  }

  // Second set of lines with different spacing
  &::after {
    background: repeating-linear-gradient(
      45deg,
      rgba(225, 225, 225, 0.568),
      rgba(0, 71, 213, 0.589) 20px,
      transparent 20px,
      transparent 40px
    );
    opacity: 0.2;
    transform: rotate(-15deg);
    overflow-x: hidden;
  }
  .section-title {
    font-size: 2.5rem;
    color: #37474f;
    margin-bottom: 30px;
    text-align: center;
    font-weight: bold;
    background: linear-gradient(90deg, #0077b6, #ab47bc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 99;
  }

  .filter-section {
    width: 100%;
    max-width: 900px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    z-index: 99;

    input {
      width: 100%;
      padding: 12px;
      border: 2px solid #00acc1;
      border-radius: 8px;
      font-size: 1.2rem;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      transition: border-color 0.3s ease;

      &:focus {
        border-color: #ab47bc;
        outline: none;
      }
    }
  }

  .banner-list {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    z-index: 99;

    .banner {
      background-color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      display: flex;
      flex-direction: column;
      padding: 20px;
      transition: all 0.3s ease;
      border-left: 8px solid #29b6f6;
      overflow: hidden;
      animation: fadeIn 0.5s ease;
      height: auto;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }

      .banner-header {
        display: flex;
        align-items: center;

        h2 {
          color: #37474f;
          font-size: 1.6rem;
          font-weight: 600;
          background: linear-gradient(to right, #0077b6, #ab47bc);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .banner-body {
        margin-top: 10px;
        color: #616161;

        p {
          margin-bottom: 15px;
          font-size: 1.1rem;
          line-height: 1.6;
          word-wrap: break-word;
        }
      }
      .visit-btn {
        background-color: #29b6f6;
        color: white;
        padding: 10px 20px;
        border-radius: 8px;
        border: none;
        font-size: 1rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0288d1;
        }

        &:active {
          background-color: #01579b;
        }
      }
    }
  }
}

/* Media Queries for responsiveness */
@media (max-width: 1200px) {
  .banner-container {
    padding: 20px !important;

    .section-title {
      font-size: 2rem !important;
    }

    .filter-section input {
      font-size: 1.1rem !important;
    }

    .banner-list .banner {
      padding: 18px !important;
    }

    .banner-header h2 {
      font-size: 1.4rem !important;
    }

    .banner-body p {
      font-size: 1rem !important;
    }
  }
}

@media (max-width: 900px) {
  .banner-container {
    padding: 15px !important;

    .section-title {
      font-size: 1.8rem !important;
    }

    .filter-section input {
      font-size: 1rem !important;
      padding: 10px !important;
    }

    .banner-list .banner {
      padding: 16px !important;
    }

    .banner-header h2 {
      font-size: 1.3rem !important;
    }

    .banner-body p {
      font-size: 0.95rem !important;
    }
  }
}

@media (max-width: 600px) {
  .banner-container {
    padding: 10px !important;

    .section-title {
      font-size: 1.6rem !important;
    }

    .filter-section input {
      font-size: 0.9rem !important;
      padding: 8px !important;
    }

    .banner-list .banner {
      padding: 14px !important;
    }

    .banner-header h2 {
      font-size: 1.2rem !important;
    }

    .banner-body p {
      font-size: 0.9rem !important;
    }

    .visit-btn {
      padding: 8px 16px !important;
      font-size: 0.9rem !important;
    }
  }
}

/* Animation for fade-in effect */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
