// Container for the entire layout
.card-layout-container {
  padding: 40px;
  background-color: #f7f8fa;
  border-radius: 20px;
  max-width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// Special People Section with modern design and unified card styling
.special-people {
  display: flex;
  flex-wrap: wrap; // Allow items to wrap
  justify-content: space-between; // Use space-between for better spacing
  width: 100%;
  max-width: 100%; // Remove max-width to take full width
  margin-bottom: 50px;

  .special-person {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(135deg, #e0e5ec, #ffffff);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(50% - 20px); // Calculate width to allow for spacing
    margin-bottom: 20px; // Add margin for spacing
    text-align: center;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: -50px;
      right: -50px;
      width: 150px;
      height: 150px;
      background: rgba(0, 123, 255, 0.1);
      border-radius: 50%;
      z-index: 0;
      transition: all 0.3s ease;
    }

    // Hover effect for the special person card
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);

      &::before {
        background: rgba(0, 123, 255, 0.2);
      }
    }

    .profile {
      z-index: 1; // Ensures the profile image appears above the background circle
      width: 90px;
      height: 90px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      transition: transform 0.3s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;
      }
    }

    .person-details {
      z-index: 1; // Ensures the text appears above the background circle

      h3 {
        font-size: 1.8rem;
        color: #004aad;
        margin-bottom: 10px;
        font-weight: 600;
      }

      p {
        font-size: 1.2rem;
        color: #666;
      }

      a {
        margin-top: 10px;
        color: #004aad;
        font-weight: bold;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
          color: #ff6f61;
        }
      }
    }

    // Zoom effect on profile image
    &:hover .profile img {
      transform: scale(1.1);
    }
  }
}

// Horizontal cards Section with new modern design
.card-container_CC {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 25px;
  max-width: 1000px;
}

.card_CC {
  position: relative;
  background: linear-gradient(135deg, #e0e5ec, #ffffff);
  border-radius: 20px;
  padding: 30px;
  width: 100%;
  max-width: 48%;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // New decorative circle background
  &::before {
    content: "";
    position: absolute;
    top: -50px;
    right: -50px;
    width: 150px;
    height: 150px;
    background: rgba(0, 123, 255, 0.1);
    border-radius: 50%;
    z-index: 0;
    transition: all 0.3s ease;
  }

  // Hover effect for the card
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);

    &::before {
      background: rgba(0, 123, 255, 0.2);
    }
  }

  .profile {
    z-index: 1; // Ensures the profile image appears above the background circle
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 20px;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }

  .card-details {
    z-index: 1; // Ensures the text appears above the background circle
    text-align: center;

    h4 {
      font-size: 1.6rem;
      color: #333;
      margin-bottom: 15px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    p {
      font-size: 1.1rem;
      color: #555;
      margin-bottom: 10px;
    }

    a {
      font-size: 1rem;
      color: #007bff;
      font-weight: bold;
      text-decoration: none;
      transition: color 0.3s ease;

      &:hover {
        color: #ff6f61;
      }
    }
  }

  &:hover .profile img {
    transform: scale(1.1);
  }
}

// Responsive Design for cards
@media (max-width: 768px) {
  .card-container_CC {
    flex-direction: column;
  }

  .card_CC {
    width: 100%;
    max-width: 100%;
  }
}

// Responsive Design for Special People Section
@media (max-width: 768px) {
  .special-people {
    flex-direction: column;

    .special-person {
      width: 100%; // Take full width on smaller screens
      margin-bottom: 25px;
    }
  }
}
.centered {
  flex: 1 1 100%;
  max-width: 100%;
  order: -1;
  display: flex;
  justify-content: center;
}
