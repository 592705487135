.search-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 15px;
  padding: 15px 0px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;

  h1 {
    font-size: 24px;
    text-align: center;
    color: #005fbf;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    margin-left: -70px;

    select {
      font-size: 16px;
      padding: 10px;
      margin: 5px 5px;
      background-color: #4caf50;
      color: #f9f9f9;
      border-radius: 5px;
      width: 20%;
    }

    input {
      padding: 10px;
      border: 1px solid #ccc;
      width: 70%;
      margin: 5px 5px;
    }

    .search-button {
      background-color: #4caf50;
      color: white;
      border: none;
      padding: 10px 15px;
      height: 100%;
      width: 10%;
      cursor: pointer;
      border-radius: 0 4px 4px 0;

      img {
        height: 25px;
      }
    }
  }

  .links {
    text-align: center;
    margin-top: 10px;

    a {
      color: #0066cc;
      text-decoration: none;
      margin: 0 5px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .search-component {
    form {
      flex-direction: column;
      justify-content: center;
      margin-left: 0;
      width: 100%;

      select {
        width: 70%;
        margin-right: 10px;
      }

      input {
        width: 70%;
        margin-right: 10px;
      }

      .search-button {
        width: 35% !important;
        padding-left: 15%;
        border-radius: 5px;
        width: auto;
      }
    }
  }
}

@media (min-width: 1024px) {
  .search-component {
    form {
      input {
        width: 600px;
      }
    }
  }
}
