.styleCard_container {
  margin: 10px 0px;
  padding: 15px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-radius: 5px;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.styleCard__wrapper {
  display: flex;
  flex-direction: row;
}

.styleCard {
  display: flex;
  width: 300px;
  padding: 1rem;
  background-color: #252525;
  border-radius: 0px 0px 12px 12px;
  justify-content: space-between;
  align-items: center;
}

.styleCard__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.styleCard__avatar {
  width: 35px;
  height: 35px;
  border-radius: 10px;
  object-fit: cover;
}

.styleCard__info span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.styleCard__info p {
  color: #919191;
  font-size: 13px;
  font-weight: 400;
}

.button_open {
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  background-color: #2d80ff;
  font-weight: 400;
  color: #fff;
  font-family: inherit;
  cursor: pointer;
  transition: 0.3s all ease;
  border: none;

  span {
    font-size: 12px;
    color: #786464;
  }
}

.button_open:hover {
  opacity: 0.8;
}

.button_open:active {
  transform: scale(0.9);
}

.styleBanner__wrapper {
  position: relative;
  //overflow: hidden;
  flex: 0 0 auto;
  scroll-snap-align: start;
  padding: 10px;
}

.styleBanner {
  margin-top: 20px;
  position: relative;
  width: 100%;
  height: 150px;
}

.styleBanner img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 12px 12px 0px 0px;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  //box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 15px rgba(0, 0, 0, 0.2);
}

.games {
  display: flex;
  flex-direction: row;
  gap: 10px;
  transition: transform 0.5s ease-in-out;
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scrollbar-width: none;
}

.arrowButtons {
  display: flex;
  gap: 10px;
  margin: 15px;
}

@media screen and (max-width: 600px) {
  .games {
    width: 100%;
  }

  .styleBanner_wrapper {
    width: 100%;
  }
}

/* Tablets (3 cards visible) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  .games {
    width: 100%;
  }

  .styleBanner_wrapper {
    width: calc(100% / 3);
  }
}

@media screen and (min-width: 1025px) {
  .games {
    width: 100%;
  }

  .styleBanner_wrapper {
    width: calc(100% / 4);
  }
}
