@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.notice-section {
  width: 100%;
  padding: 40px 20px;
  background: #fdf2e9;
  display: flex;
  justify-content: center;
}

.notice-container {
  max-width: 800px;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.notice-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #b07d62;
  margin-bottom: 15px;
}

.icon {
  font-size: 24px;
  margin-right: 8px;
}

.notice-box {
  padding: 15px;
  background: #ffe6cc;
  border-left: 4px solid #b07d62;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 10px;

  &:hover {
    background: #ffdab3;
  }
}

.notice-summary {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  z-index: 9999;
}

.modal-content {
  background: #fff;
  padding: 25px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  color: #b07d62;
  margin-bottom: 10px;
}

.close-icon {
  font-size: 22px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: #7e695e;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  background: #7e695e;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3s;

  &:hover {
    background: #5e4a42;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .notice-container {
    padding: 15px;
  }

  .notice-title {
    font-size: 14px;
  }

  .notice-summary {
    font-size: 12px;
  }

  .modal-content {
    width: 90%;
    padding: 10px;
  }

  .modal-header {
    font-size: 12px;
  }

  .close-button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
