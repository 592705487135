.footer {
  background: linear-gradient(135deg, #1c1f4a, #1a237e);

  color: #ffffff;
  padding: 40px 0;
  font-family: "Roboto", sans-serif;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    padding: 0 20px;
    max-width: 1200px;
    margin: auto;
  }

  &__social {
    display: flex;
    gap: 15px;
    justify-content: center;

    a {
      font-size: 1.5rem;
      color: #ffffff;
      transition: transform 0.3s, color 0.3s;

      &:hover {
        color: #ffeb3b;
        transform: scale(1.03);
      }
    }
  }

  &__sections {
    display: flex;
    flex-wrap: wrap;
    gap: 150px;

    .footer__section {
      h3 {
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 15px;
        color: #ffeb3b;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            color: #ffffff;
            font-size: 0.9rem;
            transition: color 0.3s;

            &:hover {
              color: #ffeb3b;
            }
          }
        }
      }
    }
  }

  &__contact {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 150px;
      margin-bottom: 10px;
      padding: 15px 15px;
      border-radius: 10px;
      background-color: #ffffff;
      transition: 0.5s;

      &:hover {
        transform: scale(1.05);
      }
    }

    .footer__contact-button {
      background-color: #ffeb3b;
      color: #1c1f4a;
      border: none;
      padding: 8px 18px;
      cursor: pointer;
      font-size: 0.9rem;
      border-radius: 5px;
      transition: background-color 0.3s;

      &:hover {
        background-color: #fdd835;
      }
    }

    .footer__contact-info {
      margin-top: 15px;
      font-size: 0.9rem;
      text-align: center;

      a {
        color: #ffeb3b;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  &__bottom {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    &__content {
      flex-direction: column;
      align-items: center;
    }

    .footer__sections,
    .footer__contact {
      text-align: center;
    }

    .footer__section {
      flex: 1 1 100%;
    }
  }

  @media (max-width: 480px) {
    &__social a {
      font-size: 1.2rem;
    }

    .footer__section h3 {
      font-size: 1rem;
    }

    .footer__contact-button {
      font-size: 0.8rem;
    }
  }
}
