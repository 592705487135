.library-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff; // Shiny black background
  padding: 30px;
  width: 100%;
  margin: auto;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}
.clock-container {
  width: 40%; // Left side for the clock
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000; // Pure black background
  padding: 20px;
  border-radius: 15px;
}

.circle-clock {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.circle-border {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  background: conic-gradient(
    #333 0%,
    transparent 0%
  ); // Default background for the border
  position: relative; // Positioning for inner circle shadow
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6); // Shadow effect
}

.circle-border::before {
  content: "";
  position: absolute;
  width: 110px;
  height: 110px;
  background-color: #000; // Inner circle background (different from the border)
  border-radius: 50%;
  z-index: 1;
  box-shadow: inset 0 0 15px rgba(255, 255, 255, 0.2); // Inner shadow to give depth
}

.circle-text {
  position: relative;
  z-index: 2; // Ensures text stays on top of the inner circle
  text-align: center;
  color: #fff;
}

.number {
  font-size: 36px; // Bigger digit size
  font-weight: bold;
}

.label {
  font-size: 12px;
  margin-top: 5px;
}

.clock-ampm,
.clock-date,
.clock-timezone {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #ffffff;
  margin: 5px 10px;
  text-align: center;
  padding: 10px 30px; // Padding inside the boxes for a clean look
  border-radius: 10px; // Rounded corners for the boxes
  background-color: #1a1a1a; // Dark background for the boxes
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); // Shadow for depth
}

.clock-ampm {
  font-size: 18px;
  letter-spacing: 1.2px;
  margin-top: 20px;
  font-weight: 500;
}

.clock-date {
  font-size: 24px;
  letter-spacing: 1.3px;
  font-weight: 500;
}

.clock-timezone {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 500;
}

.clock-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; // Pure black background to match the clock's overall style
  padding: 20px 0;
  border-radius: 15px; // Slight rounding for the overall container
}

.status {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;

  .status-icon {
    margin-right: 10px;
    animation: glowing 1.5s infinite alternate;
  }

  &.closed {
    color: #dc3545;
  }
}

.holiday-note {
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

@keyframes glowing {
  from {
    transform: scale(1);
    opacity: 0.7;
  }
  to {
    transform: scale(1.1);
    opacity: 1;
  }
}

.library-timings-container {
  width: 40%;
  background-color: #f0f8ff;
  border-radius: 15px;
  padding: 20px;
  background: linear-gradient(120deg, #fff5e1, #e0f7fa, #e6e1ff, #f4f4f9);
  background-size: 400% 400%;
  animation: timeBackgroundAnimation 12s ease infinite;
  position: relative;
  overflow: hidden;
}

/* Keyframes for background gradient animation */
@keyframes timeBackgroundAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 150% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.library-timings-container::before,
.library-timings-container::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  opacity: 0.15; /* Light opacity for all shapes */
  animation: floatShapes 15s ease-in-out infinite;
}

/* Shape 1 (large, light pink circle) */
.library-timings-container::before {
  width: 150px;
  height: 150px;
  background-color: #ff1900; /* Soft light pink */
  top: 10%;
  left: -50px;
  animation-delay: 0s;
}

/* Shape 2 (medium, light blue circle) */
.library-timings-container::after {
  width: 100px;
  height: 100px;
  background-color: #00ffbb; /* Light greenish-blue */
  bottom: -40px;
  right: -40px;
  animation-delay: 5s;
}

/* Floating animation for all shapes */
@keyframes floatShapes {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(-40px) translateX(0); /* Move slightly */
  }
}

.timing-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.timing-item {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 123, 255, 0.4);
  }

  .day {
    font-weight: bold;
    color: #0056b3;
  }

  .time {
    font-style: italic;
    color: #333;
  }

  &.highlight {
    background-color: #f8d7da;
    color: #dc3545;
  }
}

.status {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #28a745;
  display: flex;
  justify-content: center;
  align-items: center;

  .status-icon {
    margin-right: 10px;
    animation: glowing 1.5s infinite alternate;
  }

  &.closed {
    color: #dc3545;
  }
}

.holiday-note {
  text-align: center;
  font-size: 14px;
  color: #6c757d;
}

@media screen and (max-width: 1100px) {
  .circle-border {
    width: 80px;
    height: 80px;
  }

  .circle-border::before {
    content: "";
    position: absolute;
    width: 75px;
    height: 75px;
  }

  .number {
    font-size: 18px; // Bigger digit size
    font-weight: bold;
  }

  .label {
    font-size: 8px;
    margin-top: 5px;
    margin-left: 0.5px;
  }

  .clock-ampm,
  .clock-date,
  .clock-timezone {
    padding: 5px 20px;
  }
  .clock-ampm {
    margin-top: 20px;
    font-size: 14px;
  }

  .clock-date {
    font-size: 14px;
  }

  .clock-timezone {
    font-size: 14px;
  }

  .library-timings-container {
    width: 55% !important;
    margin-top: 15px;
  }

  .status {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 768px) {
  .library-container {
    flex-direction: column;
  }
  .clock-container {
    width: 90%; // Left side for the clock
  }

  .circle-border {
    width: 80px;
    height: 80px;
  }

  .circle-border::before {
    content: "";
    position: absolute;
    width: 75px;
    height: 75px;
  }

  .number {
    font-size: 18px; // Bigger digit size
    font-weight: bold;
  }

  .label {
    font-size: 8px;
    margin-top: 5px;
    margin-left: 0.5px;
  }

  .clock-ampm,
  .clock-date,
  .clock-timezone {
    padding: 5px 20px;
  }
  .clock-ampm {
    margin-top: 20px;
    font-size: 14px;
  }

  .clock-date {
    font-size: 14px;
  }

  .clock-timezone {
    font-size: 14px;
  }

  .library-timings-container {
    width: 95% !important;
    margin-top: 15px;
  }

  .status {
    font-size: 16px !important;
  }
}
