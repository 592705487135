.main-banner {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
  width: 100%;
}
.responsive-flex {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.main-banner h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.main-banner p {
  font-size: 1.2em;
}
.responsive {
  display: block;
}
@media (max-width: 768px) {
  .responsive {
    display: none;
  }
}
