.button {
  width: 280px;
  margin-right: 120px;
  padding: 10px 20px;
  font-size: 20px;
  color: #1867a5;
  background-color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    color: #fff;
    background-color: #b3d9ff;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 768px) {
  .button {
    width: 250px;
    margin-right: 0px;
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (max-width: 520px) {
  .button {
    width: 200px;
    margin-right: 0px;
    font-size: 13px;
    &:hover {
      color: #fff;
      background-color: #b3d9ff;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    }
  }
}
